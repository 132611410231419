
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import SecondaryModal from "@/components/modal/SecondaryModal.vue";
import { onBeforeMount, ref, defineComponent, inject, watchEffect } from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";

export default defineComponent({
  name: "WithdrawalDetail",
  components: {
    HourglassLoader,
    SecondaryModal,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const customComment = ref(false);
    const declineComment = ref("");

    const RequestConfirmation = inject("RequestConfirmation");

    const sureConfirmDecline = ref(false);

    const route = useRoute();

    const automatedResponses = ref([]);

    watchEffect(() => {
      document.body.classList.toggle(
        "no-scroll-on-decline-modal",
        sureConfirmDecline.value
      );
    });

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/withdrawals/crypto/transactions/${route.params.coinWithdrawalId}`
          // `/admin/coin/inward/transactions/${route.params.coinWithdrawalId}`
        );

        const getAutomatedResponses = await apiGet(
          `/admin/misc/automated/response`
        );

        automatedResponses.value =
          getAutomatedResponses.data.data.automatedResponse;

        data.value = response.data.data.transaction;
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ApproveTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/approve`
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ProcessTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/retry`,
          { status: "PROCESSING" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction Processing",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ManualTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/crypto/transactions/${route.params.coinWithdrawalId}/manual`,
          {
            status: "MANUAL",
          }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction now marked successful",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve manually: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const DeclineTransaction = async (comment: string) => {
      // const { value: text } = await Swal.fire({
      //   input: "textarea",
      //   inputLabel: "Enter Reason/Comment for declining",
      //   inputPlaceholder: "Type your reason/comment here...",
      //   inputAttributes: {
      //     "aria-label": "Type your reason/comment here",
      //   },
      //   showCancelButton: true,
      // });

      // if (!text) {
      //   return;
      // }

      loading.value = true;
      try {
        await apiPost(
          `admin/withdrawals/crypto/transactions/${route.params.coinWithdrawalId}/decline`,
          { comments: comment, status: "FAILED" }
        );
        sureConfirmDecline.value = false;

        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();
        await RefreshData();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,
      ProcessTransaction,
      ManualTransaction,
      declineComment,
      automatedResponses,
      customComment,
      sureConfirmDecline,
      RequestConfirmation,
      route,
      data,
      loading,
    };
  },
});
